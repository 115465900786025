import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Logo from "../assets/svg/logo.svg";
import NavRight from "../components/navRight";

const Nav = () => (
  <StaticQuery
    query={graphql`
      query {
        strapiGlobal {
          siteName
        }
        allStrapiCategory {
          edges {
            node {
              slug
              name
            }
          }
        }
      }
    `}
    render={() => (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          paddingRight: "16px",
          paddingLeft: "16px",
        }}
      >
        <nav
          className="uk-navbar-container uk-navbar-transparent "
          style={{
            display: "flex",
            flex: 1,
            maxWidth: 1100,
            maxHeight: 56,
            alignItems: "center",
          }}
        >
          <div className="uk-navbar-left">
            <ul className="uk-navbar-nav">
              <li>
                <Link to="/" style={{ padding: 0 }}>
                  <Logo />
                </Link>
              </li>
            </ul>
          </div>
          <div style={{ flex: 1 }} />
          <NavRight />
        </nav>
      </div>
    )}
  />
);

export default Nav;
