import React from "react";

const Footer = () => {
  const now = new Date(); // 현재 날짜 및 시간
  const year = now.getFullYear();

  return (
    <div
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        maxWidth: "1100px",
        paddingRight: "16px",
        paddingLeft: "16px",
      }}
    >
      <div
        style={{
          marginBottom: 40,
          marginTop: 40,
        }}
      >
        <p className={"footer-copyright"}>
          © {year} Tappytoon/Contents First. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
