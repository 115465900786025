import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Close from "../assets/svg/close.svg";
import MenuOutlined from "../assets/svg/menu_outlined.svg";

const DropdownContainer = styled.div`
  position: absolute;
  right: 0;
  top: 56px;
  z-index: 1;
  font-weight: 400;
  background-color: #ffffff;
  min-width: 200px;
  width: 100%;
`;

export default function NavRight() {
  const [dropdownVisible, setDropdownVisible] = React.useState(false);

  const onClick = () => {
    setDropdownVisible(!dropdownVisible);
  };

  if (typeof window !== "undefined") {
    if (window.innerWidth < 1100) {
      return (
        <div className="dropdown">
          <button className="dropbtn" onClick={onClick}>
            {dropdownVisible ? <Close /> : <MenuOutlined />}
          </button>

          {dropdownVisible ? (
            <DropdownContainer>
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: 1100,
                  paddingRight: "16px",
                  paddingLeft: "16px",
                }}
              >
                <hr style={{ marginBottom: "0px" }} />
              </div>

              <Link
                className="dropdown-content-link"
                to="/"
                target="_blank"
                rel="noopener noreferrer"
              >
                블로그 홈
              </Link>

              <Link
                className="dropdown-content-link"
                to="https://about.tappytoon.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                태피툰 소개
              </Link>

              <Link
                className="dropdown-content-link"
                to="https://about.tappytoon.com/e7432e6d-189d-4121-9081-00f71159abf5"
                target="_blank"
                rel="noopener noreferrer"
              >
                채용공고
              </Link>
              <div onClick={onClick} className={"dropdown-content-dimmed"} />
            </DropdownContainer>
          ) : null}
        </div>
      );
    }
  }

  return (
    <div style={{ flexDirection: "row", display: "flex" }}>
      <Link
        className="button nav-item-font"
        to="/"
        target="_blank"
        rel="noopener noreferrer"
      >
        블로그 홈
      </Link>
      <div style={{ width: 40, height: 1 }} />
      <Link
        className="button nav-item-font"
        to="https://about.tappytoon.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        태피툰 소개
      </Link>
      <div style={{ width: 40, height: 8 }} />
      <Link
        className="button nav-item-font"
        to="https://about.tappytoon.com/e7432e6d-189d-4121-9081-00f71159abf5"
        target="_blank"
        rel="noopener noreferrer"
      >
        채용공고
      </Link>
    </div>
  );
}
